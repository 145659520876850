export const bluePokemon = [
  'Altaria',
  'Amaura',
  'Arctovish',
  'Arctozolt',
  'Articuno',
  'Aurorus',
  'Avalugg',
  'Azelf',
  'Azumarill',
  'Azurill',
  'Bagon',
  'Beldum',
  'Bergmite',
  'Blastoise',
  'Blipbug',
  'Boldore',
  'Brionne',
  'Carracosta',
  'Ceruledge',
  'Chimecho',
  'Chinchou',
  'Clamperl',
  'Clauncher',
  'Clawitzer',
  'Cobalion',
  'Corvisquire',
  'Cosmoem',
  'Cosmog',
  'Cramorant',
  'Cranidos',
  'Croagunk',
  'Croconaw',
  'Cryogonal',
  'Deino',
  'Dewott',
  'Dialga',
  'Dondozo',
  'Dragonair',
  'Dratini',
  'Drizzile',
  'Ducklett',
  'Eelektrik',
  'Eelektross',
  'Eiscue',
  'Elgyem',
  'Empoleon',
  'Exploud',
  'Feraligatr',
  'Finizen',
  'Finneon',
  'Froakie',
  'Frogadier',
  'Gabite',
  'Garchomp',
  'Gible',
  'Gigalith',
  'Glaceon',
  'Glimmet',
  'Glimmora',
  'Gloom',
  'Golduck',
  'Grapploct',
  'Greninja',
  'Gyarados',
  'Heracross',
  'Horsea',
  'Huntail',
  'Hydreigon',
  'Inkay',
  'Inteleon',
  'Iron Crown',
  'Iron Jugulis',
  'Jumpluff',
  'Karrablast',
  'Kingdra',
  'Komala',
  'Kyogre',
  'Lanturn',
  'Lapras',
  'Latios',
  'Loudred',
  'Lucario',
  'Lumineon',
  'Luxio',
  'Luxray',
  'Malamar',
  'Manaphy',
  'Mantyke',
  'Mareanie',
  'Marill',
  'Marshtomp',
  'Masquerain',
  'Meditite',
  'Meowstic',
  'Metagross',
  'Metang',
  'Mudkip',
  'Necrozma',
  'Nidoqueen',
  'Nidoran♀',
  'Nidorina',
  'Ninetales',
  'Oddish',
  'Ogerpon',
  'Omanyte',
  'Omastar',
  'Oshawott',
  'Palafin',
  'Palpitoad',
  'Panpour',
  'Phanpy',
  'Phione',
  'Piplup',
  'Poliwag',
  'Poliwhirl',
  'Poliwrath',
  'Popplio',
  'Primarina',
  'Prinplup',
  'Quagsire',
  'Quaquaval',
  'Quaxwell',
  'Rampardos',
  'Regice',
  'Riolu',
  'Roaring Moon',
  'Roggenrola',
  'Rookidee',
  'Salamence',
  'Samurott',
  'Sawk',
  'Seadra',
  'Sealeo',
  'Seismitoad',
  'Sharpedo',
  'Shinx',
  'Simipour',
  'Sneasler',
  'Sobble',
  'Spheal',
  'Squawkabilly',
  'Squirtle',
  'Suicune',
  'Surskit',
  'Swablu',
  'Swampert',
  'Swellow',
  'Swoobat',
  'Taillow',
  'Tangela',
  'Tangrowth',
  'Tentacool',
  'Tentacruel',
  'Terapagos',
  'Thundurus',
  'Tirtouga',
  'Totodile',
  'Toxapex',
  'Toxicroak',
  'Tympole',
  'Vaporeon',
  'Vikavolt',
  'Wailmer',
  'Wailord',
  'Walking Wake',
  'Walrein',
  'Wartortle',
  'Whiscash',
  'Wishiwashi',
  'Wobbuffet',
  'Woobat',
  'Wooper',
  'Wynaut',
  'Xerneas',
  'Zacian',
  'Zweilous',
];

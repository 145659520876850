export const greenPokemon = [
  'Appletun',
  'Applin',
  'Araquanid',
  'Arboliva',
  'Axew',
  'Basculegion',
  'Basculin',
  'Bayleef',
  'Bellibolt',
  'Bellossom',
  'Bellsprout',
  'Breloom',
  'Bronzong',
  'Bronzor',
  'Budew',
  'Bulbasaur',
  'Burmy',
  'Cacnea',
  'Cacturne',
  'Calyrex',
  'Capsakid',
  'Carnivine',
  'Caterpie',
  'Celebi',
  'Celesteela',
  'Charjabug',
  'Chesnaught',
  'Chespin',
  'Chewtle',
  'Chikorita',
  'Comfey',
  'Copperajah',
  'Cottonee',
  'Cradily',
  'Cyclizar',
  'Dewpider',
  'Dhelmise',
  'Dolliv',
  'Dracovish',
  'Dracozolt',
  'Dragapult',
  'Dragonite',
  'Drakloak',
  'Drednaw',
  'Dreepy',
  'Duosion',
  'Dustox',
  'Eldegoss',
  'Electrike',
  'Flapple',
  'Floragato',
  'Flygon',
  'Fraxure',
  'Garbodor',
  'Golett',
  'Golurk',
  'Gossifleur',
  'Grookey',
  'Grotle',
  'Grovyle',
  'Gulpin',
  'Hawlucha',
  'Iron Leaves',
  'Iron Thorns',
  'Ivysaur',
  'Kecleon',
  'Larvitar',
  'Leafeon',
  'Lilligant',
  'Lombre',
  'Lotad',
  'Ludicolo',
  'Maractus',
  'Meganium',
  'Meowscarada',
  'Metapod',
  'Natu',
  'Ogerpon',
  'Pansage',
  'Petilil',
  'Politoed',
  'Poltchageist',
  'Quilladin',
  'Rabsca',
  'Rayquaza',
  'Regidrago',
  'Reuniclus',
  'Rillaboom',
  'Roselia',
  'Roserade',
  'Sandaconda',
  'Sceptile',
  'Scovillain',
  'Scyther',
  'Serperior',
  'Servine',
  'Shaymin',
  'Silicobra',
  'Simisage',
  'Sinistcha',
  'Skiploom',
  'Smoliv',
  'Snivy',
  'Solosis',
  'Spidops',
  'Spinarak',
  'Sprigatito',
  'Squawkabilly',
  'Swadloon',
  'Tarountula',
  'Thwackey',
  'Tornadus',
  'Torterra',
  'Treecko',
  'Tropius',
  'Trubbish',
  'Turtwig',
  'Tyranitar',
  'Venusaur',
  'Vibrava',
  'Victreebel',
  'Virizion',
  'Weepinbell',
  'Whimsicott',
  'Wormadam',
  'Xatu',
  'Yanmega',
  'Zarude',
  'Zygarde',
];

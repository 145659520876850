export const redPokemon = [
  'Accelgor',
  'Ariados',
  'Armarouge',
  'Bisharp',
  'Blaziken',
  'Braixen',
  'Braviary',
  'Buzzwole',
  'Camerupt',
  'Carvanha',
  'Centiskorch',
  'Charcadet',
  'Charizard',
  'Charmander',
  'Charmeleon',
  'Chi-Yu',
  'Combusken',
  'Corphish',
  'Crawdaunt',
  'Crocalor',
  'Crustle',
  'Darmanitan',
  'Darumaka',
  'Delibird',
  'Delphox',
  'Deoxys',
  'Dipplin',
  'Druddigon',
  'Dwebble',
  'Electrode',
  'Emboar',
  'Fennekin',
  'Flareon',
  'Fletchinder',
  'Fletchling',
  'Fuecoco',
  'Goldeen',
  'Groudon',
  'Heatmor',
  'Ho-Oh',
  'Incineroar',
  'Iron Bundle',
  'Jynx',
  'Kingler',
  'Klawf',
  'Koraidon',
  'Krabby',
  'Kricketot',
  'Kricketune',
  'Krookodile',
  'Latias',
  'Ledian',
  'Ledyba',
  'Litten',
  'Magby',
  'Magcargo',
  'Magikarp',
  'Magmar',
  'Magmortar',
  'Medicham',
  'Octillery',
  'Ogerpon',
  'Orbeetle',
  'Oricorio',
  'Pansear',
  'Paras',
  'Parasect',
  'Pawniard',
  'Pignite',
  'Porygon-Z',
  'Porygon2',
  'Rotom',
  'Scizor',
  'Scolipede',
  'Scrafty',
  'Seaking',
  'Shelmet',
  'Simisear',
  'Sizzlipede',
  'Skeledirge',
  'Slither Wing',
  'Slugma',
  'Solrock',
  'Talonflame',
  'Tapu Bulu',
  'Tepig',
  'Throh',
  'Torchic',
  'Torracat',
  'Turtonator',
  'Tyrantrum',
  'Venipede',
  'Vileplume',
  'Voltorb',
  'Wugtrio',
  'Wurmple',
  'Yanma',
  'Yveltal',
  'Zamazenta',
];
